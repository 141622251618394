import React, {useEffect, useState} from 'react';
import Navbar from './Components/Navbar'
import Hero from './Components/Hero'
import PickupForm from './Components/ParcelPickup/PickupForm'
import {PickupRequest, PickupResponse} from './Components/PickupResponseAndRequest'
import Confirmation from './Components/Confirmation'
import {I18n} from './common/i18n'
import ErrorBox from './Components/ErrorBox'
import {isNullOrEmpty, notNullOrEmpty} from './common/utils'
import {useAgreementsAPI} from "./hooks/useAgreementsAPI";
import {PickupAgreement} from "./Components/PickupCodeSegment";

// TODO move to somewhere
const getQueryParams = (): Record<string, string> => {
    let out: Record<string, string> = {}
    new URLSearchParams(document.location.search)
        .forEach((value: string, key: string) => out[key] = value)
    return out
}

export interface QueryParamsProps {
    q?: string
    returToken?: string
}

export interface ReturShipmentDetailsProps {
    name: string,
    address: string,
    postalCode: string,
    city: string,
    countryCode: string,
    phoneNumber?: string,
    email?: string,
    agreementCode: string
}

function App() {
    const i18n = I18n.i18n()
    const [pickupAgreement, setPickupAgreement] = useState<PickupAgreement[]>([])
    const [pickupCodeString, setPickupCodeString] = useState("")
    const [returToken, setReturToken] = useState("")
    const [returShipmentDetails, setReturShipmentDetails] = useState<ReturShipmentDetailsProps>()
    const [doRequest, setDoRequest] = useState(true)
    const [pickupConfirmation, setPickupConfirmation] = useState<PickupResponse>()
    const [hasPickupConfirmation, setHasPickupConfirmation] = useState(false)
    const [pickupRequest, setPickupRequest] = useState<PickupRequest>()
    const [searchError, setSearchError] = useState<number>(0)
    const [languageCode, setLanguageCode] = useState(I18n.getLanguage())

    const queryParams: QueryParamsProps = getQueryParams()

    if (notNullOrEmpty(queryParams.q) && isNullOrEmpty(pickupCodeString)) setPickupCodeString(queryParams.q as string)
    if (notNullOrEmpty(queryParams.returToken) && isNullOrEmpty(returToken)) setReturToken(queryParams.returToken as string)

    const {isLoading, isError, agreements, responseStatus} = useAgreementsAPI(pickupCodeString, doRequest)

    useEffect(() => {
        if (isError) {
            setSearchError(responseStatus)
            setPickupAgreement([])
            setDoRequest(false)
        }
        if (notNullOrEmpty(agreements)) {
            setSearchError(0)
            setPickupAgreement(agreements)
            setDoRequest(false)
        }
    }, [isError, agreements])



    useEffect(() => {

        const fetchData = async  () => {


            const response = await fetch(`/orderEntry/${returToken}`, {
                credentials: 'same-origin',
                headers: {
                    Accept: 'application/json',
                }})


            const data: ReturShipmentDetailsProps = await response.json()
            if(data) {
                setReturShipmentDetails(data)
                setPickupCodeString(data.agreementCode)
            }
        }

        fetchData()
    }, [returToken])


    useEffect(() => {
        setHasPickupConfirmation(notNullOrEmpty(pickupConfirmation))
    }, [pickupConfirmation])

    useEffect(() => {
        if (pickupCodeString != queryParams.q)
            window.history.replaceState({}, '', `?q=${pickupCodeString}`)
    }, [pickupCodeString])

    const onPickupCodeClick = (value: string) => {
        setPickupCodeString(value)
        setDoRequest(true)

        setPickupAgreement([])
        setPickupConfirmation(undefined)
        setHasPickupConfirmation(false)
    }

    const onLanguageChange = (value: string) => {
        window.localStorage.setItem('bring-rop-language', value)
        I18n.setLanguage(value)
        setLanguageCode(value)
    }

    const contentContainer = () => {
        const pickupForm = <PickupForm pickupAgreement={pickupAgreement}
                                       setPickupConfirmation={setPickupConfirmation}
                                       setPickupRequest={setPickupRequest}
                                       returShipmentDetails={returShipmentDetails}
            />

        const confirmationPage = <Confirmation pickupResponse={pickupConfirmation}
                                               pickupRequest={pickupRequest}/>

        const formOrMessageToShow = () => {
            if (isError) return <></>
            if (hasPickupConfirmation) {
                return confirmationPage
            } else {
                return pickupForm
            }
        }

        const showError = () => {
                switch (searchError) {
                    case 0:
                    case 200:
                        return ''
                    case 404:
                        return <ErrorBox header={i18n.error.notFound}>
                            <p>{i18n.error.notFoundInfo}</p>
                            <p>{i18n.error.notFoundInfo2}</p>
                        </ErrorBox>
                    default:
                    case 500:
                        return <ErrorBox header={i18n.error.internalError}>{i18n.error.internalErrorInfo}</ErrorBox>
                }
        }

        return (
            <div className='hw-container'>
                {formOrMessageToShow()}
                {showError()}
            </div>
        )
    }

    return (
        <div lang={languageCode}>
            <Navbar onLanguageChange={onLanguageChange}/>
            <Hero pickupCode={pickupCodeString} onPickupCodeClick={onPickupCodeClick}
                  pickupCodeError={isError} isLoading={isLoading}/>
            {isLoading || contentContainer()}
        </div>
    );
}

export default App;
