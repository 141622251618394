import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {FormProvider, useForm} from "react-hook-form";
import Address from "./Address";
import {PickupAgreement} from "../PickupCodeSegment";
import {
    isPickupResponseWithErrors,
    isValidPickupResponse,
    PickupRequest,
    PickupResponse
} from "../PickupResponseAndRequest";
import {getCountryCodesFromAgreement, makePickupRequest} from "../../common/HelperFunctions";
import {I18n} from "../../common/i18n";
import {isNullOrEmpty, notNullOrEmpty} from "../../common/utils";
import {usePickupOrder} from "../../hooks/usePickupOrder";
import ErrorBox from "../ErrorBox";
import PackagesContainerPallets from "./PackagesContainerPallets";
import {PickupDate} from "./PickupDate";
import * as Sentry from '@sentry/browser'
import {ReturShipmentDetailsProps} from "../../App";

interface Props {
    pickupAgreement: PickupAgreement[],
    setPickupConfirmation: Dispatch<SetStateAction<PickupResponse | undefined>>,
    setPickupRequest: Dispatch<SetStateAction<PickupRequest | undefined>>
    returShipmentDetails?: ReturShipmentDetailsProps
}


function PickupForm({
                        pickupAgreement,
                        setPickupConfirmation: callbackSetPickupConfirmation,
                        setPickupRequest: callbackSetPickupRequest,
                        returShipmentDetails
                    }: Props) {
    const i18n = I18n.i18n()
    const pickupCodeFound = !isNullOrEmpty(pickupAgreement)

    const [pickupRequest, setPickupRequest] = useState<PickupRequest>()
    const [pickupResponse, setPickupResponse] = useState<PickupResponse>()
    const [orderFailed, setOrderFailed] = useState(false)

    const methods = useForm<any>({mode: "all", reValidateMode: "onSubmit", defaultValues:{
            companyName: returShipmentDetails?.name ?? '',
            address: returShipmentDetails?.address ?? '',
            postalCode: returShipmentDetails?.postalCode ?? '',
            postalCity: returShipmentDetails?.city ?? '',
            phonenumber: returShipmentDetails?.phoneNumber ?? '',
            email: returShipmentDetails?.email ?? ''
        }})
    const {handleSubmit, formState, getValues} = methods
    const values = getValues()
    const product = pickupAgreement?.[0]?.product || ''

    const doRequest = notNullOrEmpty(pickupRequest) && isNullOrEmpty(formState.errors) && isNullOrEmpty(pickupResponse)
    const {isLoading, isError, responseData} = usePickupOrder(pickupRequest, doRequest)

    const [countryCode, setCountryCode] = useState('')

    const countryCodes = getCountryCodesFromAgreement(pickupAgreement)
    if (!countryCode && !isNullOrEmpty(countryCodes)) setCountryCode(countryCodes[0])

    const noMarginBottom = {
        marginBottom: 0
    }

    const onSubmit = (data: any) => {
        // Because browser autofill seems to eff up the data from the select
        if (countryCode != data.countryCode) {
            console.warn('Country code mismatch', countryCode, data.countryCode)
            data.countryCode = countryCode
        }

        const agreement = pickupAgreement.find(a => a.fromCountry == data.countryCode)

        if (notNullOrEmpty(agreement)) {
            const request = makePickupRequest(agreement as PickupAgreement, data, I18n.getShortLanguageCode())
            setPickupRequest(request)
            callbackSetPickupRequest(request)
        }
    }

    useEffect(() => {
        if (notNullOrEmpty(responseData) && !isError) {
            setPickupResponse(responseData as PickupResponse)
            if (isValidPickupResponse(responseData)) {
                callbackSetPickupConfirmation(responseData)
            } else if (isPickupResponseWithErrors(responseData)) {
                setOrderFailed(true)
                console.error('PickupOrder failed :: ' + responseData
                    .errors?.map(err => `${err.code} -> ${err.messages[0].message}`).join(';'))
                Sentry.addBreadcrumb({
                    message: 'submit pickup order',
                    data: {
                        request: pickupRequest,
                        response: responseData,
                    }
                })
            }
        }
    }, [responseData])

    // @ts-ignore
    return (
        <div>
            {pickupCodeFound && <div className='hw-block hw-block--mt hw-block--mb'>
                <div className='hw-grid'>
                    <FormProvider {...methods}>
                        <form
                            data-testid='pickupForms'
                            className='hw-flex hw-flex--twos hw-flex--gutter-larger-desktop'
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className='hw-grid__item hw-large--two-fifths hw--one-whole' style={noMarginBottom}>
                                <Address countryCodes={countryCodes} onCountryChange={setCountryCode}
                                         countryCode={countryCode}/>

                                <PackagesContainerPallets countryCode={countryCode} />

                                <PickupDate postalCode={values.postalCode} countryCode={countryCode}
                                            pickupProduct={product}/>

                                <button
                                    type='submit'
                                    className={`hw-button hw-button--primary ${isLoading ? 'hw-button__spinner' : ''}`}
                                    name='generateLabelBtn'
                                    data-testid='generateLabelBtn'
                                    disabled={isLoading}
                                >
                                    <span>{i18n.submitButton}</span>
                                </button>
                                {(isError && !isLoading) &&
                                    <ErrorBox header={i18n.error.failedSubmit}>
                                        {pickupResponse as React.ReactNode}
                                    </ErrorBox>
                                }
                                {(orderFailed && !isLoading) &&
                                    <ErrorBox header={i18n.error.failedSubmit}>
                                        <ul>
                                            {pickupResponse?.errors?.map((err, key) =>
                                                <li key={key}>{err.messages
                                                        .find(m => m.lang.localeCompare(I18n.getShortLanguageCode() , 'en', {sensitivity: 'base'}) == 0 ) ?.message
                                                    || err.messages[0]?.message
                                                    || err.code}</li>)}
                                        </ul>
                                    </ErrorBox>
                                }
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>}
        </div>
    )
}

export default PickupForm
