import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {I18n, languageCodeFromHostname} from './common/i18n';
import * as Sentry from '@sentry/browser'

Sentry.init({
    dsn: 'https://1eb86c64898a4a74ada318396d525d39@o123435.ingest.sentry.io/6310916',
    release: 'alpha',
    environment: window.location.hostname,
    denyUrls: [/google/],
})

I18n.setLanguage(window.localStorage.getItem('bring-rop-language') || languageCodeFromHostname(window.location.hostname))

document.title = I18n.i18n().hero.title

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
