import React, {useEffect, useState} from 'react'
import {useFormContext} from "react-hook-form";
import {I18n} from "../../common/i18n";
import {isNullOrEmpty, notNullOrEmpty} from "../../common/utils";
import {usePostalCodes} from "../../hooks/usePostalCodes";
import {RequiredAstrisk} from "../RequiredAstrisk";

const expectedLength: Record<string, number> = {
    DK: 4, FI: 5, NO: 4, SE: 5
}
const isValidSearchTermForCountry = (searchTerm: string, countryCode: string) => {
    if (!(new RegExp(/^[A-Z]{2}$/, 'i')).test(countryCode)) return false
    if (!(/^\d*$/.test(searchTerm))) return false
    return searchTerm.length === expectedLength[countryCode]
}

const PostalCodeLocationInput = ({countryCode: propsCountryCode}: { countryCode: string }) => {
    const i18n = I18n.i18n()

    const {register, setValue, formState: {errors}, clearErrors, setError} = useFormContext()
    const [typedSearchTerm, setTypedSearchTerm] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [postalCity, setPostalCity] = useState(useFormContext().getValues('postalCity') ?? '')
    const [countryCode, setCountryCode] = useState(propsCountryCode)

    const postalCodeLocationGrid = {
        display: 'flex',
        flexFlow: 'row wrap'
    }
    const postalCodeInputStyle = {
        marginRight: '20px',
        width: '108px'
    }
    const cityStyle = {
        flex: '1'
    }

    useEffect(() => {
        if (countryCode != propsCountryCode) {
            setValue('postalCode', '')
            setPostalCity('')
            setCountryCode(propsCountryCode)
        }
    }, [propsCountryCode])

    useEffect(() => {
        setValue('postalCity', postalCity)
    }, [postalCity])


    const sanitize = (value: string) => value.replace(/\s/g, '')

    const validatePostalCodeLength = (value: string) => {
        const sanitized = sanitize(value)
        return isValidSearchTermForCountry(sanitized, countryCode) || I18n.t('error.postalCodeRange', {MIN: expectedLength[countryCode]})
    }

    const {
        isLoading,
        isError,
        postalCodes
    } = usePostalCodes(searchTerm, countryCode, isValidSearchTermForCountry(searchTerm, countryCode))

    useEffect(() => {
        if (typedSearchTerm && countryCode) {
            const timeOutId = setTimeout(() => {
                setSearchTerm(sanitize(typedSearchTerm))
            }, 500)
            return () => clearTimeout(timeOutId)
        }
    }, [typedSearchTerm])

    useEffect(() => {
        if (!isLoading && !isError && notNullOrEmpty(postalCodes)) {
            setPostalCity(postalCodes[0]?.city)
            setValue('postalCode', postalCodes[0].postalcode)
            clearErrors(['postalCode', 'postalCity'])
        }
        if(isError && !isLoading && isNullOrEmpty(errors?.postalCity)) {
            setError('postalCity', {type: "custom", message: i18n.error.notFoundPostalCode})
        }

    }, [postalCodes, isLoading, isError])

    return (
        <div style={postalCodeLocationGrid}>
            <input type="hidden"
                   {...register("postalCity",
                       {required: {value: true, message: i18n.error.notFoundPostalCode}})}
                   defaultValue={postalCity}
            />
            <label
                className={`hw-label ${(errors.postalCode || errors.postalCity || isError) ? 'hw-label--error' : ''}`}
                style={postalCodeInputStyle}>
                {i18n.postalCode}<RequiredAstrisk/>
                <input
                    key='postalCodeInput'
                    {...register("postalCode", {
                        required: true,
                        validate: validatePostalCodeLength
                    })}
                    className={`hw-input ${(errors.postalCode || errors.postalCity || isError) ? 'hw-input--error' : ''}`}
                    type='text'
                    onChange={(e) => setTypedSearchTerm(e.target.value)}
                    disabled={isLoading}
                />
            </label>
            <label className='hw-label' style={cityStyle}>
                {i18n.city}
                {isLoading ?
                    <div className='hw-input hw-button__spinner hw-button__spinner-dark fake-disabled'/>
                    :
                    <div
                        className='hw-input hw-input fake-disabled'>
                        {errors?.postalCode?.message || errors?.postalCity?.message || postalCity}
                    </div>
                }
            </label>
        </div>
    )
}

export default PostalCodeLocationInput
